.chartdiagramm-container {
  width: 100%;
}

.chartdiagramm-unit {
  font-family: "Roboto";
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222222;
  padding-bottom: 13px;
}

.no-data-available {
  padding: 20px;
  text-align: center;
}

.chartdiagramm-body {
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
}

.chartdiagramm-header {
  display: flex;
  background-color: #eeeded;

  .chartdiagramm-title {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #3f3f3f;
    line-height: 37px;
    padding-left: 21px;
    flex-grow: 1;
  }

  .chartdiagramm-btn {
    padding-right: 17px;
    display: flex;
    align-content: center;
    justify-items: center;
    cursor: pointer;

    img {
      width: 21px;
      height: auto;
    }
  }
}
