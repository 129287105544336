.alert-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;
    margin-bottom: 1px;

    .icon {
        height: 14px;
        width: auto;
    }

    .title {
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        letter-spacing: 0.8px;
        text-align: left;
        color: #1d1d1d;
        flex-grow: 1;
        padding-left: 20px;
    }

    .action {
        font-size: 12px;
        color: #ed1c24;
        cursor: pointer;
    }
}