.modal {
  .modal-body {
    .edit-container {
      padding: 0px 20px;
      padding-top: 20px;
    }
  }
}

.custom-config-modal {
  overflow: initial !important;
}