.tree-container {
    padding-top: 20px;
    height: calc(100vh - 226px);
}

.rstcustom__collapseButton,
.rstcustom__expandButton {
    box-shadow: 0 0 0 1px #999;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    background-color: #999;
}

.rstcustom__rowContents {
    box-shadow: none;
}

.change-permission-button {
    cursor: pointer;
    margin: 0;
    padding: 0px 5px;

    &.active {
        background-color: green;
        color: white;
    }
}
