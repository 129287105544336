.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  z-index: 50;

  .footer-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
          width: auto;
          height: 24px;
          margin-top: 5px;
      }

      div {
        font-size: 9px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.89;
        letter-spacing: 0.5px;
        text-align: center;
        color: #373737;
      }

      &.active div {
        color: #ed1c24 !important;
      }
  }

  .footer-logo {
    display: none;
  }
}


@media only screen and (min-width: 600px) {
  .footer-logo {
    display: block !important;
    width: calc(100% - 32px);
    max-width: 168px;
    margin-top: 32px;
    margin-right: 32px;
  }

  .app {
    background-color: #fff;
  }

  .footer {
    background-color: #f2f0f0;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: initial !important;
    flex-direction: column;
    min-width: 232px;
    justify-content: flex-start;

    .footer-item {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-top: 32px;

      img, svg {
        margin-left: 16px;
        margin-right: 16px;
      }

      div {
        flex-grow: 1;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
      }
    }

    .footer-item:hover {
      opacity: 0.75;
    }
  }
}