.tachometer-container {
  .tachometer-header {
    height: 37px;
    background-color: #eeeded;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #3f3f3f;
    line-height: 37px;
    padding-left: 21px;
  }
  .tachometer-body {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
  }
}

.gauge-wrapper {
  display: table;
  position: relative;

  .gauge {
    width: 226px;
    position: relative;
    display: table;
    overflow: hidden;

    .leaf,
    .pointer {
      width: 100%;
    }

    .leaf {
      display: table-cell;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 10;
    }

    .pointer {
      position: absolute;
      bottom: -10px;
      left: 0;
      transition: transform 0.3s linear;
      z-index: 13;
    }

    .gauge-labels {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      top: 0px;
      overflow: hidden;

      .chart-skills {
        padding: 0px;
        margin: 0px;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .chart-skills li {
        list-style: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        border: 0px solid;
        border-top: none;
        border-bottom-left-radius: 216px;
        border-bottom-right-radius: 216px;
        transform-origin: 50% 0;
        transform-style: preserve-3d;
        backface-visibility: hidden;
      }

      .chart-skills li.green {
        background-color: #0D9625;
      }

      .chart-skills li.orange {
        background-color: #F7971F;
      }

      .chart-skills li.red {
        background-color: #E21E0F;
      }

      .chart-skills li:nth-child(1) {
        z-index: 4;
      }

      .chart-skills li:nth-child(2) {
        z-index: 3;
      }

      .chart-skills li:nth-child(3) {
        z-index: 2;
      }
    }
  }

  .labelPercentage {
    line-height: 23px;
    font-family: "Roboto";
    text-align: center;
    font-size: 35px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 23px;
    z-index: 11;
  }

  .labelMin,
  .labelMax {
    bottom: 0;
  }

  .labelMin {
    float: left;
  }

  .labelMax {
    float: right;
  }
}

.gauge {
  width: 226px;
  height: 113px;
  position: relative;
  display: table;

  .leaf,
  .pointer {
    width: 100%;
  }

  .leaf {
    display: table-cell;
  }

  .pointer {
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
