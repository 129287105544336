.ReactModal__Overlay {
  background-color: rgba($color: #000000, $alpha: 0.4) !important;
  z-index: 99;
}

.ReactModal__Content {
  position: absolute !important;
  top: 50px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: auto !important;
  max-height: 90vh !important;
  border: 0px solid rgb(204, 204, 204) !important;
  background-color: rgb(255, 255, 255) !important;
  overflow: auto !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  outline: none !important;
  padding: 0px !important;
}

.modal {
  .modal-header {
    display: flex;
    border-bottom: 1px solid #eeeded;

    .title {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 46px;
      letter-spacing: 0.9px;
      text-align: center;
      color: #ed1c24;
      flex-grow: 1;
    }

    .btn {
      line-height: 46px;
      padding: 0px 15px;
      color: #ed1c24;
      cursor: pointer;

      &--black {
        color: #000000;
      }
    }
  }

  .modal-body {
    .range-tags {
      border-bottom: 1px solid #eeeded;
      padding: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .range-tag-button {
        width: calc(15% - 12px);
        padding: 8px 14px;
        margin-right: 12px;
        margin-bottom: 12px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        background-color: #d5d4d4;
        color: #404041;
        cursor: pointer;

        &--active {
          background-color: #e10808;
          color: #ffffff;
        }
      }
    }

    .doneBtn {
        padding: 20px;
    }
  }
}
